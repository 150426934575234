import { MdMenu } from "react-icons/md";
import "./App.css";
import { Drawer, Flex, Layout } from "antd";
import { useState } from "react";
const { Header, Footer, Sider, Content } = Layout;

function App() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <Layout>
      <Header className="px-5 bg-white h-[110px]">
        <div className="w-full h-full container mx-auto flex items-center justify-between">
          <div className="size-[90px] flex items-center justify-center">
            <img
              src="/assets/images/HowCow png.png"
              className="hover:cursor-pointer"
            />
          </div>
          <div className="h-full flex items-center">
            <div
              className="h-full flex items-center justify-center lg:hidden hover:cursor-pointer"
              onClick={showDrawer}
            >
              <MdMenu className="size-10" />
            </div>
            <ul className="h-full items-center gap-7 hidden lg:flex">
              <li className="font-semibold text-base hover:cursor-pointer hover:bg-gray-200 px-3 py-2 border-b-[3px] border-b-emerald-600 hover:border-b-emerald-600 transition">
                Home
              </li>
              <li className="font-semibold text-base hover:cursor-pointer  px-3 py-2  border-b-[3px] border-b-transparent hover:border-b-emerald-600 transition">
                About Us
              </li>
              <li className="font-semibold text-base hover:cursor-pointer px-3 py-2  border-b-[3px] border-b-transparent hover:border-b-emerald-600 transition">
                Our Model
              </li>
              <li className="font-semibold text-base hover:cursor-pointer px-3 py-2  border-b-[3px] border-b-transparent hover:border-b-emerald-600 transition">
                Gallery
              </li>
              <li className="font-semibold text-base hover:cursor-pointer px-3 py-2  border-b-[3px] border-b-transparent hover:border-b-emerald-600 transition">
                Own It Now
              </li>
              <li className="font-semibold text-base hover:cursor-pointer px-3 py-2  border-b-[3px] border-b-transparent hover:border-b-emerald-600 transition">
                Our Products
              </li>
              <li className="font-semibold text-base hover:cursor-pointer px-3 py-2  border-b-[3px] border-b-transparent hover:border-b-emerald-600 transition">
                Contact Us
              </li>
            </ul>
          </div>
        </div>
      </Header>
      <Content>
        <div className="w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 relative ">
          <div className="shape rotate-180 z-[10] absolute -bottom-1 left-0 right-0 overflow-hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1000 100"
              preserveAspectRatio="none"
            >
              <path
                className="fill-gray-50"
                d="M738,99l262-93V0H0v5.6L738,99z"
              ></path>
            </svg>
          </div>

          <img
            src="/assets/images/2.jpeg"
            alt="cow2"
            className="h-[435px] aspect-video object-cover col-span-1 hidden md:block lg:block xl:block"
          />
          <img
            src="/assets/images/3.jpeg"
            alt="cow3"
            className="h-[435px] aspect-video object-cover col-span-1 lg:block xl:block hidden md:hidden"
          />
          <img
            src="/assets/images/4.jpeg"
            alt="cow4"
            className="h-[435px] aspect-video object-cover col-span-1 hidden xl:block lg:hidden md:hidden"
          />
          <img
            src="/assets/images/1.png"
            alt="cow1"
            className="h-[435px] aspect-video object-cover col-span-1"
          />
        </div>
        <div className="bg-gray-50 w-full py-10">
          <div className=" flex flex-col items-center justify-center">
            <h2 className="text-7xl sevillana-regular !font-semibold text-emerald-600">
              Welcome to
            </h2>
            <h2 className="text-4xl font-bold">HOW COW</h2>
            <h2 className="text-4xl font-bold">Our Happy Cow</h2>
          </div>
          <div className="py-16 w-full ">
            <div className="flex items-center justify-center flex-col gap-5">
              <h2 className="text-center whitespace-normal text-base lg:!text-3xl">
                For Booking, please call/ WhatsApp to: +8801701-530006
              </h2>
              <h2 className="text-center whitespace-normal text-base lg:!text-3xl">
                Email: bdocreeds@gmail.com
              </h2>
            </div>
          </div>
        </div>
      </Content>
      <Footer className="flex items-center justify-center text-base">
        &copy; Copyright {new Date().getFullYear()} All Rights Reserved
      </Footer>

      <Drawer width={300} onClose={onClose} open={open}>
        <ul className="h-full items-center gap-5 flex flex-col">
          <li className="font-semibold text-base hover:cursor-pointer  px-3 py-2 border-b-[3px] border-b-emerald-600 hover:border-b-emerald-600 transition">
            Home
          </li>
          <li className="font-semibold text-base hover:cursor-pointer  px-3 py-2  border-b-[3px] border-b-transparent hover:border-b-emerald-600 transition">
            About Us
          </li>
          <li className="font-semibold text-base hover:cursor-pointer px-3 py-2  border-b-[3px] border-b-transparent hover:border-b-emerald-600 transition">
            Our Model
          </li>
          <li className="font-semibold text-base hover:cursor-pointer px-3 py-2  border-b-[3px] border-b-transparent hover:border-b-emerald-600 transition">
            Gallery
          </li>
          <li className="font-semibold text-base hover:cursor-pointer px-3 py-2  border-b-[3px] border-b-transparent hover:border-b-emerald-600 transition">
            Own It Now
          </li>
          <li className="font-semibold text-base hover:cursor-pointer px-3 py-2  border-b-[3px] border-b-transparent hover:border-b-emerald-600 transition">
            Our Products
          </li>
          <li className="font-semibold text-base hover:cursor-pointer px-3 py-2  border-b-[3px] border-b-transparent hover:border-b-emerald-600 transition">
            Contact Us
          </li>
        </ul>
      </Drawer>
    </Layout>
  );
}

export default App;
